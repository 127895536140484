// import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import dayjs from "dayjs";
import { buyTickets, raffleDefaults } from "helpers/functions";
import { useEffect, useState } from "react";
import { Form, Tab, Table, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import NumberPicker from "react-widgets/cjs/NumberPicker";
import styled from "styled-components";
import Countdown from "react-countdown";

import DiscordLogo from "../images/discord.svg";
import TwitterLogo from "../images/twitter.svg";
import {
  useRaffleEntries,
  useTotalTickets,
  useRaffleParticipants,
  useUserTickets,
  useUnstakedNfts,
  useStakedNFTs,
  useStakedFarmers,
} from "helpers/hooks";
import { StakedData } from "helpers/helpers";

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

const RaffleDetails = ({ match }: { match: any }) => {
  const raffle = raffleDefaults.find((element) => {
    return element.raffleObjectId === match.params.id;
  });

  const date1 = dayjs(raffle.endDate);
  const ended = date1.diff(new Date(), "second") < 0;

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const unstakedNfts: any[] = useUnstakedNfts(raffle.holderOnly);
  const farmers: any = useStakedFarmers(raffle.holderOnly);
  const stakedNfts: StakedData[] = useStakedNFTs(farmers);

  const [value, setValue] = useState(1);
  const [discordId, setDiscordId] = useState("");
  const [refresh, setRefresh] = useState(0);

  const { entries, isLoading: entriesIsLoading } = useRaffleEntries(raffle);
  const userTickets = useUserTickets(raffle, refresh);
  const totalTickets = useTotalTickets(raffle, ended, refresh);
  const { participants, isLoading } = useRaffleParticipants(
    entries,
    entriesIsLoading
  );

  useEffect(() => {
    let isCancelled = false;

    if (raffle && !isCancelled) {
      setRefresh(Math.random);
    }

    return () => {
      isCancelled = true;
    };
  }, [raffle]);

  const raffleAction = async () => {
    if (publicKey === null) {
      toast.error("Please connect your wallet.");
    } else if (raffle.type === "whitelist" && discordId === "") {
      toast.error("Please enter your Discord ID");
    } else {
      const id = toast.loading("Processing Transaction...");
      const res = await buyTickets(
        raffle,
        value,
        connection,
        publicKey,
        sendTransaction,
        raffle.type === "whitelist" && discordId
      );

      if (res?.err === null) {
        await toast.success("Ticket Purchased.", { id });
        setRefresh(Math.random());
      } else {
        toast.error("Something went wrong.", { id });
      }
    }
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <>Ended</>;
    } else {
      // Render a countdown
      return (
        <>
          {days > 0 && `${days}d`} {hours > 0 && `${hours}hrs`}{" "}
          {minutes > 0 && `${minutes}m`} {String(seconds).padStart(2, "0")}s
        </>
      );
    }
  };

  return (
    <>
      <RaffleDetailsContainer>
        <div>
          <RaffDetailsImg
            src={raffle.raffleImage}
            style={raffle.background && { background: raffle.background }}
          />
        </div>
        <RaffleTabsContainer>
          <RaffleDetailContainer>
            <RaffleDetailsInfoHeader>
              <RaffleDetailsInfoTitle>
                <h3>{raffle.raffleName}</h3>
                <ul>
                  {raffle.twitterLink && (
                    <li>
                      <a
                        href={raffle.twitterLink}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <img src={TwitterLogo} alt="Twitter" />
                      </a>
                    </li>
                  )}
                  {raffle.discordLink && (
                    <li>
                      <a
                        href={raffle.discordLink}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <img src={DiscordLogo} alt="Discord" />
                      </a>
                    </li>
                  )}
                </ul>
              </RaffleDetailsInfoTitle>
            </RaffleDetailsInfoHeader>
            {raffle.raffleDesc && (
              <p
                style={{ fontSize: 16, lineHeight: "24px" }}
                dangerouslySetInnerHTML={{ __html: raffle.raffleDesc }}
              />
            )}
            <Tabs
              defaultActiveKey="details"
              id="raffle-details"
              transition={false}
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <p style={{ fontSize: 18 }}>
                  <span>Entries:</span>{" "}
                  {ended ? raffle.ticketsSold : totalTickets ?? 0}/
                  {raffle.entries}
                </p>
                <p style={{ fontSize: 18 }}>
                  <span>Winners:</span> {raffle.winners}
                </p>
                <PWithBorder style={{ fontSize: 18 }} title={raffle.endDate}>
                  {!ended ? (
                    <>
                      <span>Ends in:</span>{" "}
                      <Countdown date={raffle.endDate} renderer={renderer} />
                    </>
                  ) : (
                    "Closed"
                  )}
                </PWithBorder>
                {ended ? (
                  <>
                    {raffle?.winner?.length > 0 ? (
                      <>
                        <p>Congratulations! 🎉</p>
                        <div>
                          {raffle?.winner?.map((winner) => (
                            <p key={winner}>{winner}</p>
                          ))}
                        </div>
                      </>
                    ) : (
                      <p>Winners are being drawn.</p>
                    )}
                  </>
                ) : totalTickets > raffle?.entries ? (
                  <p>Sold out! 🧨</p>
                ) : raffle.holderOnly &&
                  unstakedNfts.length <= 0 &&
                  stakedNfts.length <= 0 ? (
                  <p style={{ margin: 0 }}>
                    You need to hold a Zenjin Viper to participate in this
                    raffle, pick one up off secondary{" "}
                    <a
                      href="https://magiceden.io/marketplace/zenjinviperz"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </p>
                ) : (
                  <>
                    <p>
                      {raffle.limit && (
                        <>Max {raffle.limit} ticket per wallet. </>
                      )}
                      1 ticket costs <span>{raffle.pricePerTicket} $SNEK</span>{" "}
                      {raffle.solPerTicket && (
                        <span>and {raffle.solPerTicket} SOL</span>
                      )}
                    </p>
                    {raffle.limit && raffle.limit === userTickets ? (
                      ""
                    ) : (
                      <RaffleDetailsButtonContainer>
                        <RaffleDetailsPickerContainer>
                          <NumberPicker
                            defaultValue={1}
                            min={1}
                            max={raffle.limit ? raffle.limit : 1000000}
                            value={value}
                            onChange={(value) => setValue(value)}
                          />
                        </RaffleDetailsPickerContainer>
                        {raffle.type === "whitelist" && (
                          <Form.Group
                            controlId="formBasicEmail"
                            style={{
                              margin: 0,
                              maxWidth: 200,
                              marginRight: 24,
                            }}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Enter Discord ID"
                              onChange={(e) => setDiscordId(e.target.value)}
                              defaultValue={discordId}
                            />
                          </Form.Group>
                        )}
                        <button
                          className="button"
                          onClick={() => raffleAction()}
                        >
                          Buy Tickets
                        </button>
                      </RaffleDetailsButtonContainer>
                    )}
                  </>
                )}
                {userTickets > 0 && (
                  <p
                    style={{
                      fontSize: 16,
                      color: "#555",
                      marginBottom: 0,
                      marginTop: 12,
                    }}
                  >
                    You have purchased {userTickets ?? 0} tickets!
                  </p>
                )}
              </Tab>
              <Tab eventKey="participants" title="Participants">
                {isLoading ? (
                  <p
                    style={{
                      fontSize: 16,
                      color: "#555",
                      marginBottom: 0,
                      marginTop: 12,
                    }}
                  >
                    Loading...
                  </p>
                ) : (
                  participants?.length > 0 && (
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>Wallet</th>
                          <th>Tickets</th>
                        </tr>
                      </thead>
                      <tbody>
                        {participants.map((entry, i) => {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <tr key={i}>
                              <td>{entry.wallet}</td>
                              <td>{entry.tickets}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )
                )}
              </Tab>
            </Tabs>
          </RaffleDetailContainer>
        </RaffleTabsContainer>
      </RaffleDetailsContainer>
    </>
  );
};

export default RaffleDetails;

const RaffleDetailContainer = styled.div`
  background: #fff;
  border-radius: 16px;
  padding: 32px;

  p {
    color: #231f3d;
    margin-bottom: 8px;
  }
`;

const PWithBorder = styled.p`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 16px !important;
  padding-bottom: 16px;
`;

const RaffleTabsContainer = styled.div`
  .tab-pane {
    max-height: 400px;
    overflow: auto;
  }

  table {
    border: 0;
    font-size: 14px;

    thead {
      th {
        border: 0;
        font-weight: 400;
      }
    }
  }

  .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    padding-bottom: 12px;

    .nav-link {
      border: 0;
      border-radius: 30px;
      box-shadow: none;
      color: #eb4b44;
      padding: 6px 24px;

      &.active {
        background: #eb4b44;
        color: #231f3d;
      }
    }
  }
`;

const RaffleDetailsContainer = styled.div`
  display: grid;
  grid-gap: 32px;
  margin-bottom: 16px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 450px 1fr;
  }
`;

const RaffDetailsImg = styled.img`
  background: #eee;
  border-radius: 16px;
  height: auto;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 450px;
    width: 450px;
  }
`;

const RaffleDetailsInfoHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  a {
    color: #fff;
    font-size: 20px;
    text-decoration: underline;
  }
`;

const RaffleDetailsInfoTitle = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  h3 {
    color: #231f3d;
    font-family: "Share Tech";
    font-size: 32px;
    margin: 0;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    top: -3px;

    li {
      margin-left: 16px;
    }
  }
`;

const RaffleDetailsButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  /* margin-top: 12px; */

  .button {
    background: #eb4b44;
    border: 0;
    border-radius: 30px;
    color: #231f3d;
    font-family: "Share Tech";
    font-size: 18px;
    height: 38px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
  }
`;

const RaffleDetailsPickerContainer = styled.div`
  margin-right: 24px;
  max-width: 120px;
`;
