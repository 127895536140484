import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { web3 } from "@project-serum/anchor";

export const raffleDefaults = [
  {
    raffleObjectId: "62b08dcad6ebc49300381454",
    raffleId: "1",
    pricePerTicket: 25,
    receiverWallet: "ZR1CfLFAz44hw3Dxsev7h6gVMZu8e2RwKXaiQdxcfEJ",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-06-20T18:00:00+00:00",
    endDate: "2022-06-25T18:00:00+00:00",
    raffleName: "Fox #6284",
    raffleImage:
      "https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://ipfs.io/ipfs/QmdPwM81uu1ofvE11kcJNYC7Gg2fFRXjKmZSFabzXoghjz",
    raffleDesc:
      "The Famous Fox Federation, an independent organization of the most fabulously famous foxes on the Blockchain.",
    twitterLink: "https://twitter.com/famousfoxfed",
    discordLink: "https://discord.com/invite/famousfoxes",
    tokenLink:
      "https://solscan.io/token/4ceA6dHcteh9HFjeQrJ2KV7RnyCL3ay9Y1qauWx84eFf",
    winner: ["8d8tNL5Mnk8B4CUwSFmjafy9XdHsX12Z1qSTZJ9zG6CM - 20 Entries"],
    type: null,
    ticketsSold: 899,
    seoImage: "fff",
    entries: 2500,
  },
  {
    raffleObjectId: "62ba9b3afa51d5c39d14fa37",
    raffleId: "2",
    pricePerTicket: 25,
    receiverWallet: "ZR1CJRLxEEEjxxyzpt53rRoC7qu6J5ffPgUE2apthgf",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-06-28T12:00:00+00:00",
    endDate: "2022-07-03T12:00:00+00:00",
    raffleName: "Mad Scientist #835",
    raffleImage:
      "https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://arweave.net/mX0BiSKH01_ewRb_GvIJ0-U2y3XwhvHRahcPgP-MYFU",
    raffleDesc:
      "5001 Communi3 Mad Scientists building the future communities in Web3.",
    twitterLink: "https://twitter.com/communi3_io",
    discordLink: "https://discord.gg/communi3",
    tokenLink:
      "https://solscan.io/token/ACPxLeP3Vts3c5c2A7Zk1pdPopVRqRdfuhmUiKy7SWAM",
    winner: ["B32JtrjB5wUv82TX6dzwxvHA7PRdEmgJyerq9YBQu4FJ - 5 Entries"],
    type: null,
    ticketsSold: 974,
    seoImage: "communi",
    entries: 2500,
  },
  {
    raffleObjectId: "62c549cc09677c21b14afedc",
    raffleId: "3",
    pricePerTicket: 5,
    receiverWallet: "ZR1oKLtTwKNQpC2qW7cgBKUeyC6NrxHnFiQduUVRdNc",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 5,
    startDate: "2022-07-06T10:00:00+00:00",
    endDate: "2022-07-06T22:00:00+00:00",
    raffleName: "5x Shrouded WL",
    raffleImage:
      "https://pbs.twimg.com/media/FW5DQnpX0AIlRnd?format=jpg&name=large",
    raffleDesc:
      "Shrouded Playground is a lifestyle based art, utilising a collection of open minded mavericks, eccentrics and gangsters to takeover the Solana Playground.",
    twitterLink: "https://twitter.com/shroudednft",
    discordLink: "https://discord.com/invite/shroudedplayground",
    tokenLink: null,
    winner: [
      "8s9J6t82rNyqcZ1AbMovpHyQjSthHvHUAR41sLnq7GKM - Daftcryptobakedpunk#0202",
      "H3bq3Dpv1c6Nj5Yx4RZtHU4D8LNjJv7RenNn2RSDEtC3 - leductam#0567",
      "Gn1TLWGrKpyArGWssMFhiSggBWPREW4d49W1bwk1wXbo - xizerion#6987",
      "5DuyzeoE8w58VUjNCXa8Tzuwzc46N56Z8sBVSXvc3xCV - kevinpㅣX Moose#5955",
      "4w82qfBEZ9HmqApBMeWA3ZJ9Jf7uDyFAsbWWh16H4dRL - Bullen#4209",
    ],
    type: "whitelist",
    ticketsSold: 922,
    seoImage: "shrouded",
    entries: 1000,
  },
  {
    raffleObjectId: "62c6ffc8778ef18aed68794c",
    raffleId: "4",
    pricePerTicket: 5,
    receiverWallet: "ZR1xj4mVRwZAsynaKzTo4SmnqtJmAR7NfZa5mE9V3sZ",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 10,
    startDate: "2022-07-07T12:00:00+00:00",
    endDate: "2022-07-09T16:00:00+00:00",
    raffleName: "10x Oak Paradise WL",
    raffleImage:
      "https://pbs.twimg.com/media/FXEC9FPacAAyKsM?format=jpg&name=4096x4096",
    raffleDesc: "The woods have eyes...",
    twitterLink: "https://twitter.com/OakParadiseNFT",
    discordLink: "https://discord.gg/oakparadise",
    tokenLink: null,
    winner: [
      "8s9J6t82rNyqcZ1AbMovpHyQjSthHvHUAR41sLnq7GKM - Daftcryptobakedpunk #0202",
      "67kkC3R7BUVxYGEoo9KLRo5YDjv4sPA1gv14WjJJst1t - ocin26#0402",
      "2Su8h3q1zqJakt2tB5k5k6xdz1uxFc59jzV8ZMMDWhX8 - b00ner#2628",
      "EMgEcF4jfUJ7XHB156A8VbGPnBVAXfeGED1AGSGGG1ts - JJJJ#5029",
      "DG8miRw5KP6b7PdH9JZmSE4PLHC4FNhHBzsEPUMFJoTm - cCcGrayWOLF03CcC / Enes#9915",
      "CBt7sjMPHqBxLYs8WqaTuKM2VP1kwDUF3GBGCKYUjVz6 - Icepick | LONGBEARD#1926",
      "JDMWKgmzrbje3YDS8CV4qQZAgHxefiv2NenwSChvx7gG - Parn | Cryptid Clan#8126",
      "1pAQ61arJ1KvriVrhRAogGKUcn8MLenVzF54sNkC1kn - Alisher#8376",
      "VWi89uQdLraJNr8Uai4qmWMRsiJFnUmYQaATFobk8vh - Dsea#3063",
      "FG3ZLJX6ihpbxty6ohDtjiGNV2oWw4PVasvqWmAVK1o - justwycked#9737",
    ],
    type: "whitelist",
    ticketsSold: 598,
    seoImage: "oaklab",
    entries: 1000,
  },
  {
    raffleObjectId: "62cde5cac82be37d1de95372",
    raffleId: "5",
    pricePerTicket: 25,
    receiverWallet: "ZR1HPSTQkSnQTHEtkxvsZQVuFQEtbnCKxU6JwYH3e9Y",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-07-12T22:00:00+00:00",
    endDate: "2022-07-22T22:00:00+00:00",
    raffleName: "Aurorian #3882",
    raffleImage:
      "https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://arweave.net/hC2f919Kfs1s4qgbylY-o71ouGorPh1DEYFvSaLvwoc",
    raffleDesc:
      "Aurory, a PvE/PvP gaming project using NFTs, powered by Solana and Serum.",
    twitterLink: "https://twitter.com/AuroryProject",
    discordLink: "https://discord.gg/aurory",
    tokenLink: null,
    winner: ["FYBfc7ss355T3DZpxeY8yrqvTHcjQFSWwb7PzqACMQKV - 24 Entries"],
    type: null,
    ticketsSold: 1487,
    seoImage: "aurory",
    entries: 5000,
  },
  {
    raffleObjectId: "62d437cbdaecb677325aae25",
    raffleId: "6",
    pricePerTicket: 10,
    receiverWallet: "ZR1moZjT5ZTLydZtTnXLxUciMy5QaVLMNV5tQJP8xi7",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 5,
    startDate: "2022-07-17T18:00:00+00:00",
    endDate: "2022-07-18T00:00:00+00:00",
    raffleName: "5x Parrots WL",
    raffleImage:
      "https://pbs.twimg.com/profile_images/1552738954121732096/0UjuP83w_400x400.jpg",
    raffleDesc: "SOL NFT Copy Trading Platform",
    twitterLink: "https://twitter.com/ParrotTools",
    discordLink: "http://discord.gg/parrotflock",
    tokenLink: null,
    winner: [
      "A3ngSeveSRdiq6M1vRunKXLxbtnMwf9nBLcB29bH549f - pogo#3312",
      "8s9J6t82rNyqcZ1AbMovpHyQjSthHvHUAR41sLnq7GKM - Daftcryptobakedpunk #0202",
      "3dH7MYn7UzZb1ahQF2cGou6C4jWzFr7VZLqfVvJvpHV9 - Sahil2k#3186",
      "GkabVBEfsaFjS4LGX4YCbkxzbUzQKVKteQb9DPurk7FF - babymasako#9603",
      "4dQvs1ez34p8CMNCwzPhzE6eqbha2s3xWbtAhwTm4sJe - Selfishvic#1006",
    ],
    type: "whitelist",
    ticketsSold: 84,
    seoImage: "parrots",
    entries: 1000,
  },
  {
    raffleObjectId: "62de69cb022de0ec03cb88c5",
    raffleId: "7",
    pricePerTicket: 25,
    receiverWallet: "ZR12FQKctrvUPbLdLTEz9NMoboJoc9qDS6PDXzufDTx",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-07-25T18:00:00+00:00",
    endDate: "2022-07-30T18:00:00+00:00",
    raffleName: "OG Atadians #2189",
    raffleImage:
      "https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://www.arweave.net/fTUal18w63S3v5R5pnBnWTKJVsFdDzcU6hV3mxcLfiU?ext=png",
    raffleDesc:
      "Atadia is a collective analytics NFT project centered around a lore-filled, post-singularity world. Holders receive pencil-drawn PFP art, exposure to drive and share the success of our analytics business, and $ATA passive income with turn-based alternate reality game mechanics.",
    twitterLink: "https://www.twitter.com/atadia_io",
    discordLink: "https://www.discord.gg/zJpUKPF62f",
    tokenLink: null,
    winner: ["GLVJEcRFeRhcrnBArXZ7Uf84kWNkCNJbBS3LWbMdV9ws - 25 Entries"],
    type: null,
    ticketsSold: 610,
    seoImage: "atadian",
    entries: 2500,
  },
  {
    raffleObjectId: "62df02ccca230f77e57df19fz",
    raffleId: "8",
    pricePerTicket: 5,
    receiverWallet: "ZR1iM65K5PoJ9tVmMq4yqcSUW9PyEAZbM1VWaUPD6mW",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 20,
    startDate: "2022-07-25T22:00:00+00:00",
    endDate: "2022-08-04T22:00:00+00:00",
    raffleName: "20x Front Runners WL",
    raffleImage:
      "https://d1fdloi71mui9q.cloudfront.net/lVGrfFSMQ4y88EzM2AyX_hlAYfznWeoputgI0",
    raffleDesc:
      "Degen/Free Mint. Building an inclusive gamified virtual lifestyle. It's a marathon, not a sprint.",
    twitterLink: "https://twitter.com/FRsLab",
    discordLink: "https://discord.gg/frontrunnersnft",
    tokenLink: null,
    winner: [
      "BwqV4jm28jKrrL1Qpir8wMkHpva5DSfffBCASUTp8q6N - surya1adiga#5853",
      "HfAydvgGZMXUjSBme5WDJM7trsWGk9NdaXiQ3Zn6nDqW - 13izzness#5365",
      "CcLZ76DwvmWJ6CcRsC6qKa3ZygLnW7AhjFoe1AZJdA8Y - Nexus#0307",
      "1pAQ61arJ1KvriVrhRAogGKUcn8MLenVzF54sNkC1kn - Alisher#8376",
      "8uKMU8Xe2ryXLTs6X27Q35qY5a1iQv4TSfsk5tXvWqZy - Alexvie#6029",
      "FdVpxE64jLYoxuyNkHhapAz8gk41ZL8Rbv9fPk3FWBs3 - Yiquo#4689",
      "kwQbiR4ER4gKchpToXTeUGZb2mjQyMXuQB8Gk1JAgNS - Tim8#3416",
      "5CNgzQ1ynLRH2q2Jz18fh8KHwxVFP7DUNVnmNsBt8dkd - 908086215742480415",
      "7dzt5PbnaNTNKQWLKxb3EYyHHvg9heYbMKTt9vMTqZpQ - Zikman#2436",
      "DRPeMowpMdNNLzmsq6TydXPiMUosLAgtcJGmxPAVDNYA - imatemu#5069",
      "DctSodFvZipjoN2R7w9ERV2iYUZQYk216RTfWe8xrNWj - Goned75#9230",
      "7oQkSwh8kTgMmwbFbeVexiuBbEyenosAQT36oguarKBV - auto1#8854",
      "B3voZjC52VEpoz9w4cBNFUT1EKUWrzF6J71VFmWnYf5U - FilosoF#7063",
      "9mNFZVeSjjbFUWj7hcaDW87SzTetqmsEjsz1AAXPSv1n - AMMAR#4520",
      "HoXFpFk1pjaCN7G7YbK5Tfb4AWz46uFGP1C6Cg7FZmMp - KadirY#1940",
      "9X9iGP6aN1D2MT3BD3xVDhjWeSp6FfvmhVFQVykpW7Vc - talkless1#6493",
      "9TYv3xCRtMssnWMY3y1fBXdC2t44pgdeq2Ac4EHtrFCy - Baddi#1493",
      "DYW2rwunYwcD6PCHyKtjT4YzjN5bVarUgDfAyyuc6Uzi - Berserkboy#5031",
      "DG8miRw5KP6b7PdH9JZmSE4PLHC4FNhHBzsEPUMFJoTm - cCcGrayWOLF03CcC / Enes#9915",
    ],
    type: "whitelist",
    ticketsSold: 181,
    seoImage: "frontrunners",
    entries: 1000,
  },
  {
    raffleObjectId: "62e582c62b906e01c87b10b4",
    raffleId: "9",
    pricePerTicket: 5,
    receiverWallet: "ZR1u6pTYjuvcfpQZYRfW64HR5u9JL5aQ1AmYRivVwTu",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 10,
    startDate: "2022-07-30T22:00:00+00:00",
    endDate: "2022-08-03T22:00:00+00:00",
    raffleName: "10x CryptoTitans WL",
    raffleImage:
      "https://pbs.twimg.com/profile_images/1500880589087514626/iZVuWUVB_400x400.jpg",
    raffleDesc: "Mint, Collect, and Battle in the Epic World of Ereo ⚔️",
    twitterLink: "https://twitter.com/OfficialCTitans",
    discordLink: null,
    tokenLink: null,
    winner: [
      "Fnwf9xBkSqDbntfhYU1dFdvftWxHwJdjUUdpTzAiSMwx - Rasmus / Zenjin Viperz#1795",
      "HfAydvgGZMXUjSBme5WDJM7trsWGk9NdaXiQ3Zn6nDqW - 13izzness#5365",
      "6uRTq9X7tsYbfjbHyuqv7UZqUnkFvUnqq6crFfaDSYtt- xNFT Momona#6969",
      "45jTzC9D2mzpdJGrKQfKg5bEytyDkdhW637tMCMxEahb - emre#4321",
      "DctSodFvZipjoN2R7w9ERV2iYUZQYk216RTfWe8xrNWj - Goned75#9230",
      "8uKMU8Xe2ryXLTs6X27Q35qY5a1iQv4TSfsk5tXvWqZy - Alexvie#6039",
      "7dzt5PbnaNTNKQWLKxb3EYyHHvg9heYbMKTt9vMTqZpQ - Zikman#2436",
      "JYx3Vh2vHbJxrDdD4bpPYfNscTBKjueHh746j3wJHk3 - DreamingAbout.Crypto#2406",
      "DYW2rwunYwcD6PCHyKtjT4YzjN5bVarUgDfAyyuc6Uzi - Berserkboy#5031",
      "9X9iGP6aN1D2MT3BD3xVDhjWeSp6FfvmhVFQVykpW7Vc - talkless1#6493",
    ],
    type: "whitelist",
    ticketsSold: 106,
    seoImage: "cryptotitans",
    entries: 1000,
  },
  {
    raffleObjectId: "62e6652b4cac7f0199245b72",
    raffleId: "10",
    pricePerTicket: 5,
    receiverWallet: "ZR11ShLSn7Yz64WM7EhzQ7qikPVWvUuxEN6jjnDu3Yi",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 5,
    startDate: "2022-07-10T22:00:00+00:00",
    endDate: "2022-08-04T22:00:00+00:00",
    raffleName: "5x Sovereign WL",
    raffleImage:
      "https://pbs.twimg.com/profile_images/1552265194834800640/APfFscUv_400x400.jpg",
    raffleDesc: "The Sovereigns of 64 squares are here to rule the Metaverse.",
    twitterLink: "https://twitter.com/thesovereignnft",
    discordLink: "https://discord.gg/SovereignNFT",
    tokenLink: null,
    winner: [
      "GzQVb2QxEA4MziH5VLcPtgYVTCwNqupkSP8VhSQvF4jZ - charlesmackenzie#6156",
      "BgfKXrioMyJZ6LNH9deK3HvydtaK54KjaxYCwzUbCc34 - Redacted#1600",
      "CcLZ76DwvmWJ6CcRsC6qKa3ZygLnW7AhjFoe1AZJdA8Y - Nexus#0307",
      "FEN8BwrcscgWG7v4KfWYmGtfirrdSqvRMMMxPZCHUWXv - DOPE VampireWalker#5415",
      "JYx3Vh2vHbJxrDdD4bpPYfNscTBKjueHh746j3wJHk3 - DreamingAbout.Crypto#2406",
    ],
    type: "whitelist",
    ticketsSold: 94,
    seoImage: "sovereign",
    entries: 1000,
  },
  {
    raffleObjectId: "62e926a0e8620d85e5b12626",
    raffleId: "11",
    pricePerTicket: 5,
    receiverWallet: "ZR1tQPSb7cFuuzuuQzuaw92tZcB2JrBJ7xqrnKXR1bD",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 40,
    startDate: "2022-08-02T12:00:00+00:00",
    endDate: "2022-08-07T12:00:00+00:00",
    raffleName: "40x Dope Pigeons WL",
    raffleImage:
      "https://pbs.twimg.com/profile_images/1549762520973443073/HuDeUxtU_400x400.jpg",
    raffleDesc:
      "What a Dope Pigeon PFP. Gambling games that generate revenue for holders. Organic Building. By degens for degens.",
    twitterLink: "https://twitter.com/dopepigeons",
    discordLink: "https://t.co/WPNJduHGJ0",
    tokenLink: null,
    winner: [
      "5U1f9KagpkMUwWGpb9cRJmtCZT9cfDFtPrdQpi1n5xhd - levimx.eth#2008",
      "FWDuBfxJ1CT3PVpr5m43j1dsLmvyN6ppmVJK7LCq3XEg - jokerz#0067",
      "kwQbiR4ER4gKchpToXTeUGZb2mjQyMXuQB8Gk1JAgNS - Tim8#3416",
      "BwqV4jm28jKrrL1Qpir8wMkHpva5DSfffBCASUTp8q6N - surya1adiga#5853",
      "AuYBuhdHEHukuUvAiUkdJfu47Mf8WviY23bUiS4ZL1Sh - $ SAM | Ahmad1994#5701",
      "GY2fufwMfNMurYLPBoVwLmPUTDTV3NL1zTBmFp8PvxNH - KASEI13",
      "54qrGL1MaPxZtKmDh5veEqd1CggL8Uj5rFr5ZmsH1YYq - the_rambor{REX}#5876",
      "EEFHjQjgXriEgaxKv3thjtXxAgHwn4ptb7DGXyGndMes - itqleyva#0581",
      "DctSodFvZipjoN2R7w9ERV2iYUZQYk216RTfWe8xrNWj - Goned75#9230",
      "HPhVxwestjBwhZc1tHTJKEovwFWAifLB3WWGGu7mEd1f - jkow#4132",
      "84XXCyjh4ENz3J8NH2GMcuuNoozcPBBh19LxkxmwkHsD - merrykrismats#1299",
      "5QcH3PBjPwDwtVbMM4aYyLeQ8LqBSbzF4E7bi6CEvRoS - thánhGA#3052",
      "DYW2rwunYwcD6PCHyKtjT4YzjN5bVarUgDfAyyuc6Uzi - Berserkboy#5031",
      "A7tdHDoSicWKP6pHjqQoLpVmGNK1tdj4VaJQzwz53XHN - LORD_AUSAR#2198",
    ],
    type: "whitelist",
    ticketsSold: 47,
    seoImage: "pigeons",
    entries: 1000,
  },
  {
    raffleObjectId: "62e927c46ba0b423c4c53867",
    raffleId: "12",
    pricePerTicket: 25,
    receiverWallet: "ZR1d1E9WCKEBEuxH92aoHVti1egbC3t4pq5M65ixRGW",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-08-02T12:00:00+00:00",
    endDate: "2022-08-08T10:00:00+00:00",
    raffleName: "Degen Fat Cat #721",
    raffleImage: "https://degenfatcats.s3.amazonaws.com/assets/720.png",
    raffleDesc:
      "Most cats curl up and nap. Some take up the blade to protect their kingdom and nature. Others dabble in coin and entertainment. Few fingers find forgotten tomes for untold magics. As for all of us? We're all Fat Cats.",
    twitterLink: "https://twitter.com/degenfatcats",
    discordLink: "https://discord.gg/degenfatcats",
    tokenLink:
      "https://solscan.io/token/9v2ij1tqk8UyKr6e1KXwmjLtyXRr9GMRsYY8PtEjrAP5#metadata",
    winner: ["4dQvs1ez34p8CMNCwzPhzE6eqbha2s3xWbtAhwTm4sJe - 20 Entries"],
    type: null,
    ticketsSold: 790,
    seoImage: "dgfc",
    entries: 2500,
  },
  {
    raffleObjectId: "62eafed2862ad5d8fe6a8d2c",
    raffleId: "13",
    pricePerTicket: 100,
    receiverWallet: "ZR1w5xSTMFCfWzLgbeH9JSHmWHCa6VQrcmUncP1XehU",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 5,
    startDate: "2022-08-04T00:00:00+00:00",
    endDate: "2022-08-12T12:00:00+00:00",
    raffleName: "5x NANO S LEDGER",
    raffleImage:
      "https://cdn.discordapp.com/attachments/978232375123247184/1004526927282974821/nano.png",
    raffleDesc:
      "Secure, buy and grow your crypto assets with the world's most popular hardware wallet.",
    twitterLink: null,
    discordLink: null,
    tokenLink: null,
    winner: [
      "JDMWKgmzrbje3YDS8CV4qQZAgHxefiv2NenwSChvx7gG - Parn | Cryptid Clan#8126",
      "HhRMJPLAKeo2aUuScvEwNGuMJSwsyMiAgKpiAhn7qSgy - sewento#5999",
      "GAUbZW4KUgtgdyEnQRU8TXZAFYkEphmGxmQL3wd1FJ5R - Abed#1096",
      "8L3S973HHzE88Rq745SaPizGhEmpuwcq7ww7gpc6yrpr - JooBaa#7005",
      "7wgMg8eva3HYocoyms6LRyL6pWVZ171wY8ojGEMs7K35 - van1tu#5039",
    ],
    type: "whitelist",
    ticketsSold: 74,
    seoImage: "ledger",
    entries: 2000,
    limit: 1,
    holderOnly: true,
  },
  {
    raffleObjectId: "62ff7656040421a4fc9095b8z",
    raffleId: "14",
    pricePerTicket: 100,
    solPerTicket: 0.02,
    receiverWallet: "ZR1SuZk66LHqXgnVS3zSBpQmCxEywaL8HcW74Ysm2HF",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-08-19T12:00:00+00:00",
    endDate: "2022-08-26T12:00:00+00:00",
    raffleName: "1x y00ts WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/955203812711350392/1010148378920890408/1_body.webp",
    raffleDesc: "we're building y00topia, together.",
    twitterLink: "https://twitter.com/y00tsNFT",
    discordLink: null,
    tokenLink: null,
    winner: ["J5ZqR3NeUviZNs3ZDH5bnjzGRq11aAxGerSekq62CLG - Tenka#5029"],
    type: "whitelist",
    ticketsSold: 615,
    seoImage: "y00ts",
    entries: 5000,
    limit: null,
    holderOnly: true,
  },
  {
    raffleObjectId: "6308be8fddeef29654c574c8",
    raffleId: "15",
    pricePerTicket: 50,
    solPerTicket: 0.01,
    receiverWallet: "ZR1X1YfprJXzbNMLN2fNafoAQoj8tUP7mX6WJAAz3BT",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-08-26T12:00:00+00:00",
    endDate: "2022-08-27T18:00:00+00:00",
    raffleName: "1x y00ts WL",
    raffleImage:
      "https://www.y00ts.com/_next/image?url=%2Fimages%2Freveal%2F11_body.png&w=640&q=75",
    raffleDesc: "we're building y00topia, together.",
    twitterLink: "https://twitter.com/y00tsNFT",
    discordLink: null,
    tokenLink: null,
    winner: ["6Q7AS9KqmGtHNzHitGcum8e9vvNHkHL48XREXszgVkSc - pley0s0l#8968"],
    type: "whitelist",
    ticketsSold: 623,
    seoImage: "y00ts",
    entries: 5000,
    limit: null,
    holderOnly: false,
    background: "#AEC0FF",
  },
  {
    raffleObjectId: "630de225d37cc024f1ac10de",
    raffleId: "16",
    pricePerTicket: 25,
    solPerTicket: null,
    receiverWallet: "ZR1gEWhbAqnq6EWvwKcQnsiQkNBLidnUYBjARjjNmGD",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-08-30T12:00:00+00:00",
    endDate: "2022-09-06T12:00:00+00:00",
    raffleName: "SOLGods 3456",
    raffleImage:
      "https://www.arweave.net/3QYbo5pgyr8lE7PX3-UeQ_V-yvRenXRmMtFe-9JgkZc?ext=png",
    raffleDesc:
      "Inspired by metaphysical artist Giorgio de Chirico. The Gods are the legacy 6666 collection within 'The Fracture'. A Brand Born in Blockchain.",
    twitterLink: "https://twitter.com/TheFracture_",
    discordLink: "https://discord.gg/thefracture",
    tokenLink:
      "https://solscan.io/token/FGUgSbHip8WQN9mLZvCxK3S2TC4dGGHaqgHCvytBW2nS",
    winner: ["4q95ynnmtMEaA4VKeGSFDV2S4nQDeM5N7ReMYKsrVPdo - 150 Entries"],
    type: null,
    ticketsSold: 1017,
    seoImage: "solgods",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "6310c9fc2adc35c4b311e1eb",
    raffleId: "17",
    pricePerTicket: 5,
    solPerTicket: null,
    receiverWallet: "ZR1pv6QPHXUpoe4T1KBvA2BhULiuTeHL5fjhgXphAic",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 5,
    startDate: "2022-09-01T12:00:00+00:00",
    endDate: "2022-09-06T15:00:00+00:00",
    raffleName: "5x UKIYO WL",
    raffleImage:
      "https://cdn.discordapp.com/attachments/1010550337108709476/1010550598363512843/unknown.png",
    raffleDesc:
      "Ukiyo is more than a brand, it’s a lifestyle. A unique collection that brings a never before seen style to Solana 🌆",
    twitterLink: "https://twitter.com/UkiyoNFT_",
    discordLink: "https://discord.gg/ukiyonft",
    tokenLink: null,
    winner: [
      "BDdLJuZhE7xk54NhtG9xn9FCfbYnVPuk91xbDzCNXUs7 - Evelyn~#0006",
      "97dneAJ13jHJ2LQDaWLtNgXfhkV5nGaGPcirwyZW8aEM - Kairlgy#3714",
      "CZtC8p4Sz3Jmtsi5FyihwoqH1rm4g2DfRy3FyV9Naa3J - casey#4725",
      "6uRTq9X7tsYbfjbHyuqv7UZqUnkFvUnqq6crFfaDSYtt - xNFT Momona#6969",
      "45jTzC9D2mzpdJGrKQfKg5bEytyDkdhW637tMCMxEahb - emre#4321",
    ],
    type: "whitelist",
    ticketsSold: 874,
    seoImage: "UKIYO",
    entries: 5000,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "6318f7ac9842ed0429a0b1cd",
    raffleId: "18",
    pricePerTicket: 25,
    solPerTicket: null,
    receiverWallet: "ZR1XxLd6KCSGFgD6vtM3GL8LtZhrxft1o7zKVhRqHqP",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-09-07T20:00:00+00:00",
    endDate: "2022-09-14T20:00:00+00:00",
    raffleName: "Trippin' Ape Tribe #3960",
    raffleImage: "https://cdn.valhallalabs.xyz/ape/3960.png?ext=png",
    raffleDesc:
      "10,000 Apes have fallen under the trance of a mysterious yet charismatic leader, Chorles, but don't worry… it's definitely not a cult.",
    twitterLink: "https://twitter.com/TrippinApeNFT",
    discordLink: "https://discord.com/invite/trippinapetribe",
    tokenLink:
      "https://solscan.io/token/48A2HSxC4DqTCFJJguA7RfZXXmYk9NQP9ZcNYtUJnbC8",
    winner: ["GZ8SAD8ebL3eMo1vBGF7cvnzzy3aFRhZeXoXV7gUJzEb - 40 Entries"],
    type: null,
    ticketsSold: 1041,
    seoImage: "tat",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "6325c4056bc3cd66f709c7d5z",
    raffleId: "19",
    pricePerTicket: 15,
    solPerTicket: null,
    receiverWallet: "ZR1UH85D3QtXi7eVbpkR789HseeKFP59T5X6haCJoAp",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-09-17T13:00:00+00:00",
    endDate: "2022-09-21T13:00:00+00:00",
    raffleName: "Suave Seal #458",
    raffleImage:
      "https://www.arweave.net/PzivrNpjw4r1bl7K4pi9z96Y4Nr6YwdjwsIzEgjTuzk?ext=png",
    raffleDesc: "777 of the Suavest Seals on Solana",
    twitterLink: "https://www.twitter.com/SuaveSeals",
    discordLink: "https://www.discord.gg/wUvDpz6ENr",
    tokenLink:
      "https://solscan.io/token/82sJwRTJTLvzi65jba69BfrGoB9u5A6NNzGQvAoGnn4d",
    winner: ["7ZSHfKYaqJy19FAmYjhMTYHLay9tk64aixNXHBLVyjn3 - 10 Entries"],
    type: null,
    ticketsSold: 381,
    seoImage: "seal",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "6325c4ae47bbd91dc29a33b6",
    raffleId: "20",
    pricePerTicket: 15,
    solPerTicket: null,
    receiverWallet: "ZR1N4umfqS6KtHmFYvbUmtopyi6732VNfvBRV5d74aa",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-09-17T13:00:00+00:00",
    endDate: "2022-09-22T13:00:00+00:00",
    raffleName: "Boryoku Babyz #3311",
    raffleImage:
      "https://arweave.net/pYlstqhT53ha_0IECA5b_43oic6jgNteFCkQkmxtQhY?ext=png",
    raffleDesc:
      "Bōryoku Babyz is an exclusive collection of 5,000 Baby Dragon NFTs on the Solana Blockchain, offsprings of the Genesis Dragonz.",
    twitterLink: "https://twitter.com/BoryokuDragonz",
    discordLink: "https://discord.gg/jSUWCYm3rF",
    tokenLink:
      "https://solscan.io/token/4juRPGq5vznyP5TA79aFBHoHCYHxhPmF7bbxtKokUBVk",
    winner: ["BwqV4jm28jKrrL1Qpir8wMkHpva5DSfffBCASUTp8q6N - 15 Entries"],
    type: null,
    ticketsSold: 831,
    seoImage: "bory-baby",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "633e9b0c8dc9b3b72b83b813",
    raffleId: "21",
    pricePerTicket: 15,
    solPerTicket: null,
    receiverWallet: "ZR118uVR4onFKvq4zLBAfoPXUCRpNGoUfGKUHvB998L",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2022-10-06T10:00:00+00:00",
    endDate: "2022-10-12T10:00:00+00:00",
    raffleName: "Zenjin Viperz #757",
    raffleImage:
      "https://shdw-drive.genesysgo.net/GQwFqs7P6CkWZYiLdcqS8dDEWYtKxsqm8h8D4Pk7upJe/757.gif",
    raffleDesc:
      "Zenjin Viperz are an exclusive collection of 2,000 NFTs on the Solana Blockchain.",
    twitterLink: "https://twitter.com/ZenjinViperz",
    discordLink: "https://discord.gg/ZenjinViperz",
    tokenLink:
      "https://solscan.io/token/4vC89nXBVgY38yHFP1VLVMFsYPbENN2wNpVfwWUgH56Q#metadata",
    winner: ["6uRTq9X7tsYbfjbHyuqv7UZqUnkFvUnqq6crFfaDSYtt - 10 Entries"],
    type: null,
    ticketsSold: 1167,
    seoImage: "zenjin-crown",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "63dfb3aeae6f2a8c90b97024",
    raffleId: "22",
    pricePerTicket: 50,
    solPerTicket: null,
    receiverWallet: "ZR1AZgSmoNnjFydRZwmRy87cvA64oAE7GZBiFQK7DG9",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2023-20-05T10:00:00+00:00",
    endDate: "2023-02-12T10:00:00+00:00",
    raffleName: "JellyDawgs #7333",
    raffleImage:
      "https://arweave.net/hZgkwm1El6sIfVkf2kik77URJTRaY6NpJ2GDABzYKEA",
    raffleDesc: "A DAOs Best Friend",
    twitterLink: "https://www.twitter.com/JellyeSportsNFT",
    discordLink: "https://t.co/0nrClokEO5",
    tokenLink:
      "https://solscan.io/token/HkM5EP1LZ5NxKTn7crMcoxXb8SuxKkbfMK26L6xZETbq#metadata",
    winner: ["56svzZMsC6CjW8sdtUecBk4UDtJ4F3pnEg6Xh2Ywt87z"],
    type: null,
    ticketsSold: 1129,
    seoImage: "zenjin-crown",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "63f342f50a38293fdc2c1b28",
    raffleId: "23",
    pricePerTicket: 50,
    solPerTicket: 0.02,
    receiverWallet: "ZR1civp28kk1qSydHKYz1zuPrUsZET7RAoTJfTyyKYm",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2023-02-20T10:00:00+00:00",
    endDate: "2023-02-27T10:00:00+00:00",
    raffleName: "Claynosaurz #565",
    raffleImage:
      "https://nftstorage.link/ipfs/bafybeicm6yqbcwh7ktmiata5pwc4rc4jja7fog2ugl2djbkfj7m7rtplee/564.gif",
    raffleDesc:
      "Travel back to distant lands. Explore the uncharted, discover lost artifacts. Adventure awaits…",
    twitterLink: "https://www.twitter.com/Claynosaurz",
    discordLink: "https://www.discord.gg/claynosaurz",
    tokenLink:
      "https://solscan.io/token/HQGWy3gPHKytoWSDgFn3ax84GuvExZwXGqdVWkN5stBi",
    winner: ["57xdpNW35kENhoMrQshAupE1bhzCxfcZ4onhf4Hh4C1k - 15 Entries"],
    type: null,
    ticketsSold: 1038,
    seoImage: "zenjin-clay",
    entries: 2500,
    limit: null,
    holderOnly: true,
    background: null,
  },
  {
    raffleObjectId: "63fc7bfe742646d8638ee334",
    raffleId: "24",
    pricePerTicket: 50,
    solPerTicket: 0.01,
    receiverWallet: "ZR1FyPcEeYxBitNBJ8J18u2uCNp2ddop1W9KWWeTrkC",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2023-02-27T10:00:00+00:00",
    endDate: "2023-03-06T10:00:00+00:00",
    raffleName: "Ovol #3187",
    raffleImage:
      "https://shdw-drive.genesysgo.net/HLqiJ3yEN5A5tL5FXDCPqySTmadHH22YER6LFmArb2am/3187.png",
    raffleDesc:
      "Protectors of the City of Elixir. Prepared to protect and serve the City through any conditions.",
    twitterLink: "https://www.twitter.com/ElixirNFT",
    discordLink: "https://www.discord.gg/elixirnft",
    tokenLink:
      "https://solscan.io/token/C34DDwuQeNjFubt5tt2JDM7eSMiB5rSbpGzRnLRivmZT",
    winner: ["52uPdKLfeesUAyNz7ALCD6pG43PhnPzY2381Efpz5V4u - 200 Entries"],
    type: null,
    ticketsSold: 869,
    seoImage: "zenjin-oval",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "6405b795c07003bc8e31a53a",
    raffleId: "25",
    pricePerTicket: 50,
    solPerTicket: null,
    receiverWallet: "ZR1e2hbhabhEdiK8U5k319ZXA97rjZqWSA7bHguLZVG",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2023-02-06T10:00:00+00:00",
    endDate: "2023-03-13T10:00:00+00:00",
    raffleName: "LILY #500",
    raffleImage:
      "https://bafybeicvbzvcpo6loyaqxsmlwqv5zlwo72tluwjoqxnoeh3vo7odswo6me.ipfs.nftstorage.link/7434.png?ext=png",
    raffleDesc:
      "LILY is a generative art project made up of 10,000 digital collectibles. Holding one grants you access to our community of optimalists.",
    twitterLink: "https://www.twitter.com/THELILYNFT",
    discordLink: "https://www.discord.gg/vs8VvHb35k",
    tokenLink:
      "https://solscan.io/token/51f9hUjGqgunbKaRtCRdZszcw6o4fZKovKgQj925pvzL",
    winner: ["57xdpNW35kENhoMrQshAupE1bhzCxfcZ4onhf4Hh4C1k"],
    type: null,
    ticketsSold: 1087,
    seoImage: "zenjin-lily",
    entries: 5000,
    limit: null,
    holderOnly: false,
    background: null,
  },
  {
    raffleObjectId: "64ad5f9ae5b4773c1787ef70",
    raffleId: "26",
    pricePerTicket: 200,
    solPerTicket: null,
    receiverWallet: "ZR1MipaPvJneAshMvRGicKpYaDxTfewnSc9XcgUCF8X",
    tokenDecimal: 9,
    tokenMint: "ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx",
    winners: 1,
    startDate: "2023-07-11T10:00:00+00:00",
    endDate: "2023-07-18T10:00:00+00:00",
    raffleName: "The Heist",
    raffleImage:
      "https://arweave.net/moVfWP2AmUDPwJ1D-56KOPxo86FMhUp9455lbBtheac?ext=png",
    raffleDesc:
      "The Heist is a high-stakes, risk based game of crime, corruption...and bananas. The Heist is the 2nd gaming title from web3 studio, Longwood Labs.",
    twitterLink: "https://twitter.com/playtheheist",
    discordLink: "https://discord.com/invite/theheist",
    tokenLink:
      "https://solscan.io/token/4ZijjVPR1Mc8Vvr8HVFgX36AGQ9Ffof3dXS1QrjeCu76",
    winner: ["9VN1hV84YeTzTGRzjQ94WJeCktM3SZdPJxeEtsroMdTr - 103 Entries"],
    type: null,
    ticketsSold: 767,
    seoImage: "zenjin-heist",
    entries: 2500,
    limit: null,
    holderOnly: false,
    background: null,
  },
];

export const tx_transfer_spl = async (
  conn: Connection,
  from: PublicKey,
  to: PublicKey,
  token: PublicKey,
  amount: number,
  decimals: number
) => {
  const source = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    token,
    from
  );

  if ((await conn.getParsedAccountInfo(from)).value == null) {
    console.error("can't debit from an account that doesn't exist");
    return;
  }

  const dest = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    token,
    to
  );

  const txs = new Transaction();

  if ((await conn.getParsedAccountInfo(dest)).value == null) {
    txs.add(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        token,
        dest,
        to,
        from
      )
    );
  }

  txs.add(
    Token.createTransferCheckedInstruction(
      TOKEN_PROGRAM_ID,
      source,
      token,
      dest,
      from,
      [],
      amount * Math.pow(10, decimals),
      decimals
    )
  );

  return txs;
};

export const buyTickets = async (
  raffle: any,
  n_tickets: number,
  connection: any,
  publicKey: PublicKey,
  sendTransaction: Function,
  discordId?: string
) => {
  const txs = new Transaction();

  const amount = n_tickets * raffle.pricePerTicket;

  const transferIx = await tx_transfer_spl(
    connection,
    publicKey!,
    new PublicKey(raffle.receiverWallet),
    new PublicKey(raffle.tokenMint),
    amount,
    raffle.tokenDecimal
  );

  if (raffle.solPerTicket) {
    const solAmount = n_tickets * raffle.solPerTicket;

    const transferTransaction = SystemProgram.transfer({
      fromPubkey: publicKey!,
      toPubkey: new PublicKey(raffle.receiverWallet),
      lamports: solAmount * web3.LAMPORTS_PER_SOL,
    });

    txs.add(transferTransaction);
  }

  txs.add(
    new TransactionInstruction({
      keys: [{ pubkey: publicKey!, isSigner: true, isWritable: true }],
      data: Buffer.from(
        `Raffle #${raffle.raffleId} - ${amount / raffle.pricePerTicket}`,
        "utf-8"
      ),
      programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
    })
  );

  discordId &&
    txs.add(
      new TransactionInstruction({
        keys: [{ pubkey: publicKey!, isSigner: true, isWritable: true }],
        data: Buffer.from(
          `${discordId ? `Discord ID - ${discordId}` : ""}`,
          "utf-8"
        ),
        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
      })
    );

  if (transferIx === null || transferIx === undefined) {
    return;
  }

  txs.add(transferIx);

  try {
    let blockhashObj = await connection.getRecentBlockhash();
    txs.recentBlockhash = blockhashObj.blockhash;
    txs.feePayer = publicKey!;

    const txid = await sendTransaction(txs, connection);

    let done = false;
    let status = {
      slot: 0,
      confirmations: 0,
      err: null,
    };
    // let subId = 0;

    status = await new Promise(async (resolve, reject) => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        console.log("Rejecting for timeout...");
        reject({ timeout: true });
      }, 120000);

      while (!done) {
        // eslint-disable-next-line no-loop-func
        (async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                done = true;
                resolve(status);
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
          }
        })();
        await sleep(2000);
      }
    });

    done = true;
    console.log("Returning status", status);
    return status;
  } catch (e) {
    console.error(e);
  }
};

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
