import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

import RaffleItem from "components/RaffleItem";
import { raffleDefaults } from "helpers/functions";

const RaffleHome = ({
  loading,
  setLoading,
}: {
  loading: Boolean;
  setLoading: Function;
}) => {
  return (
    <>
      <div>
        <CustomTabs defaultActiveKey="raffles">
          <Tab eventKey="raffles" title="Raffles">
            <Container>
              {raffleDefaults
                .sort((a, b) => (a.endDate < b.endDate ? 1 : -1))
                .map((raffle) => (
                  <RaffleItem raffle={raffle} key={raffle.raffleId} />
                ))}
            </Container>
          </Tab>
          {/* <Tab eventKey="auctions" title="Auctions" disabled></Tab> */}
        </CustomTabs>
      </div>
    </>
  );
};

export default RaffleHome;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin: -8px;
`;

const CustomTabs = styled(Tabs)`
  border: 0;
  margin-bottom: 40px;

  .nav-item {
    background: transparent;
    border: 0;
    color: rgba(255, 255, 255, 0.5);
    font-family: "Share Tech";
    font-size: 40px;
    margin-right: 34px;
    padding: 0;

    &.active {
      background: transparent;
      border: 0;
      border-bottom: 4px solid #eb4b44;
      color: #fff;
    }
  }
`;
