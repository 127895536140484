// globalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  
  .wallet-adapter-modal-title {
    color: #fff;
  }

  .wallet-multi {
    font-family: "Share Tech", sans-serif;
  }

  button {
    transition: 0.25s ease-in-out all;
  }
`;

export default GlobalStyle;
