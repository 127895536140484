import { Connection, PublicKey } from "@solana/web3.js";
import { ProgramAccount, Wallet } from "@project-serum/anchor";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import {
  // findFarmerPDA,
  // findWhitelistProofPDA,
  GemBankClient,
  GemFarmClient,
  GEM_BANK_PROG_ID,
  GEM_FARM_PROG_ID,
} from "../gem-sdk";

import { IDL as GemBank } from "../gem-sdk/types/gem_bank";
import { IDL as GemFarm } from "../gem-sdk/types/gem_farm";
import axios from "axios";

const bankIdl = GemBank;
const farmIdl = GemFarm;

const creatorId = process.env.REACT_APP_CREATOR_ID;

export interface UnstakedData {
  mint: PublicKey;
  data: MetadataDataData;
  json: any;
}

export interface StakedData extends UnstakedData {
  farmer: any;
  isStaked: boolean;
}

export type UnstakedNFTs = UnstakedData[];
export type StakedNFTs = StakedData[];

export const initGemBank = (conn: Connection, wallet: SignerWalletAdapter) => {
  return new GemBankClient(
    conn,
    wallet as unknown as Wallet,
    bankIdl as any,
    GEM_BANK_PROG_ID
  );
};

export const initGemFarm = (conn: Connection, wallet: SignerWalletAdapter) => {
  return new GemFarmClient(
    conn,
    wallet as unknown as Wallet,
    farmIdl as any,
    GEM_FARM_PROG_ID,
    bankIdl as any,
    GEM_BANK_PROG_ID
  );
};

export async function populateVaultNFTs(
  connection: Connection,
  walletAdapter: SignerWalletAdapter,
  farmer: ProgramAccount
) {
  let currentVaultNFTs = [];

  let gb = await initGemBank(connection, walletAdapter);

  const vault = farmer.account.vault;

  let foundGDRs = await gb.fetchAllGdrPDAs(vault);

  if (foundGDRs && foundGDRs.length) {
    let mints = foundGDRs.map((gdr) => {
      return { mint: gdr.account.gemMint };
    });

    currentVaultNFTs = await getNFTMetadataForMany(mints, connection);

    return currentVaultNFTs;
  }
}

export async function getNFTMetadataForMany(
  mints: {
    mint: PublicKey;
  }[],
  conn: Connection
) {
  const promises: any[] = [];

  mints.forEach((t) => promises.push(getNFTMetadata(t.mint, conn)));
  const nfts = (await Promise.all(promises)).filter((n) => !!n);

  const filteredNfts = nfts?.filter((nft) => {
    if (
      nft?.onchainMetadata?.data?.creators &&
      nft?.onchainMetadata?.data?.creators[0]?.verified &&
      nft?.onchainMetadata?.data?.creators[0]?.address === creatorId
    ) {
      return nft;
    } else {
      return null;
    }
  });

  return filteredNfts;
}

async function getNFTMetadata(mint: PublicKey, conn: Connection) {
  try {
    const metadataPDA = await Metadata.getPDA(mint);
    const onchainMetadata = (await Metadata.load(conn, metadataPDA)).data;
    const externalMetadata = (await axios.get(onchainMetadata.data.uri)).data;
    return {
      mint: new PublicKey(mint),
      onchainMetadata,
      externalMetadata,
    };
  } catch (e) {
    // console.log(`failed to pull metadata for token ${mint}`);
  }
}
