import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID } from "containers/RaffleDetails";
import { useState, useEffect, useMemo, useCallback } from "react";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import {
  initGemFarm,
  populateVaultNFTs,
  StakedData,
  UnstakedData,
} from "./helpers";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import { ProgramAccount } from "@project-serum/anchor";
import { raffleDefaults } from "./functions";

export function useTotalSnekBurnt() {
  const result = useMemo(
    () =>
      raffleDefaults.reduce(
        (total, currentValue) =>
          (total =
            total + currentValue?.pricePerTicket * currentValue?.ticketsSold),
        0
      ),
    []
  );

  return result;
}

export function useRaffleEntries(raffle) {
  const { connection } = useConnection();
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getEntries = useCallback(async () => {
    setIsLoading(true);
    const test = await PublicKey.findProgramAddress(
      [
        new PublicKey(raffle.receiverWallet).toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        new PublicKey(raffle.tokenMint).toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    );

    if (test) {
      const sigs = await connection.getSignaturesForAddress(
        new PublicKey(test[0]?.toString()),
        {
          limit: 1000,
        }
      );

      sigs?.forEach(async (sig, i) => {
        if (sig?.err === null) {
          const trans = await connection.getParsedTransaction(sig.signature);

          const ownerObject = trans?.meta?.logMessages?.filter((word) =>
            word.includes("Program log:")
          );

          const owner = ownerObject[0].substring(23);

          const array = Array.from(
            {
              length: Number(
                sig.memo && raffle.raffleId.length > 1
                  ? sig.memo.substring(13, 15) === raffle.raffleId
                    ? raffle.type === "whitelist"
                      ? sig.memo
                          .substring(
                            sig.memo.indexOf(""),
                            sig.memo.lastIndexOf(";")
                          )
                          .substring(18)
                      : sig.memo.substring(18)
                    : 0
                  : sig.memo.substring(13, 14) === raffle.raffleId
                  ? raffle.type === "whitelist"
                    ? sig.memo
                        .substring(
                          sig.memo.indexOf(""),
                          sig.memo.lastIndexOf(";")
                        )
                        .substring(17)
                    : sig.memo.substring(17)
                  : 0
              ),
            },
            (v, i) =>
              `${owner} - ${Number(
                sig.memo && raffle.raffleId.length > 1
                  ? sig.memo.substring(13, 15) === raffle.raffleId
                    ? raffle.type === "whitelist"
                      ? sig.memo
                          .substring(
                            sig.memo.indexOf(""),
                            sig.memo.lastIndexOf(";")
                          )
                          .substring(18)
                      : sig.memo.substring(18)
                    : 0
                  : sig.memo.substring(13, 14) === raffle.raffleId
                  ? raffle.type === "whitelist"
                    ? sig.memo
                        .substring(
                          sig.memo.indexOf(""),
                          sig.memo.lastIndexOf(";")
                        )
                        .substring(17)
                    : sig.memo.substring(17)
                  : 0
              )}`
          );

          array.forEach((a) => {
            setEntries((entries) => [...entries, a]);
          });
        }

        if (i === sigs.length - 1) {
          setIsLoading(false);
        }
      });
    }
  }, [
    connection,
    raffle.raffleId,
    raffle.receiverWallet,
    raffle.tokenMint,
    raffle.type,
  ]);

  useEffect(() => {
    let isCancelled = false;

    if (raffle && !isCancelled) {
      getEntries();
    }

    return () => {
      isCancelled = true;
    };
  }, [getEntries, raffle]);

  return { entries, isLoading };
}

export function useTotalTickets(raffle, ended: boolean, refresh?: number) {
  const { connection } = useConnection();
  const [totalTickets, setTotalTickets] = useState(0);

  useEffect(() => {
    let isCancelled = false;

    if (!!ended) return;

    if (!isCancelled) {
      getTotalTickets();
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [raffle, refresh, ended]);

  async function getTotalTickets() {
    const test = await PublicKey.findProgramAddress(
      [
        new PublicKey(raffle.receiverWallet).toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        new PublicKey(raffle.tokenMint).toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    );

    if (test) {
      const sigs = await connection.getSignaturesForAddress(
        new PublicKey(test[0]?.toString()),
        {
          limit: 1000,
        }
      );
      const result = sigs.reduce(
        (total, currentValue) =>
          (total =
            total +
            Number(
              currentValue && raffle.raffleId.length > 1
                ? currentValue.memo?.substring(13, 15) === raffle.raffleId
                  ? raffle.type === "whitelist"
                    ? currentValue.memo
                        ?.substring(
                          currentValue.memo.indexOf(""),
                          currentValue.memo.lastIndexOf(";")
                        )
                        .substring(18)
                    : currentValue.memo?.substring(18)
                  : 0
                : currentValue.memo?.substring(13, 14) === raffle.raffleId
                ? raffle.type === "whitelist"
                  ? currentValue.memo
                      ?.substring(
                        currentValue.memo.indexOf(""),
                        currentValue.memo.lastIndexOf(";")
                      )
                      .substring(17)
                  : currentValue.memo?.substring(17)
                : 0
            )),
        0
      );
      setTotalTickets(result);
    }
  }

  return totalTickets;
}

export function useUserTickets(raffle, refresh) {
  const { connection } = useConnection();
  const { publicKey } = useWallet();

  const [userTickets, setUserTickets] = useState(0);

  async function getUserTickets() {
    const test = await PublicKey.findProgramAddress(
      [
        publicKey?.toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        new PublicKey(raffle.tokenMint).toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    );

    if (test) {
      const sigs = await connection.getSignaturesForAddress(
        new PublicKey(test[0]?.toString()),
        {
          limit: 1000,
        }
      );

      const result = sigs.reduce(
        (total, currentValue) =>
          (total =
            total +
            Number(
              currentValue && raffle.raffleId.length > 1
                ? currentValue.memo?.substring(13, 15) === raffle.raffleId
                  ? raffle.type === "whitelist"
                    ? currentValue.memo
                        ?.substring(
                          currentValue.memo.indexOf(""),
                          currentValue.memo.lastIndexOf(";")
                        )
                        .substring(18)
                    : currentValue.memo?.substring(18)
                  : 0
                : currentValue.memo?.substring(13, 14) === raffle.raffleId
                ? raffle.type === "whitelist"
                  ? currentValue.memo
                      ?.substring(
                        currentValue.memo.indexOf(""),
                        currentValue.memo.lastIndexOf(";")
                      )
                      .substring(17)
                  : currentValue.memo?.substring(17)
                : 0
            )),
        0
      );
      setUserTickets(result);
    }
  }

  useEffect(() => {
    let isCancelled = false;

    if (!publicKey) return;

    if (!isCancelled) {
      getUserTickets();
    }

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey, refresh]);

  return userTickets;
}

export function useRaffleParticipants(entries, entriesIsLoading) {
  const [participants, setParticipants] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getParticipants = useCallback(async () => {
    if (entries.length === 0) {
      return;
    }

    setIsLoading(true);
    const sortedShifts = entries
      .map((dataItem) => dataItem.substring(0, 44)) // get all dates
      .filter((startDate, index, array) => {
        return array.indexOf(startDate) === index;
      }); // filter out duplicates

    setParticipants(
      sortedShifts.map((shift, i) => {
        if (i === sortedShifts.length - 1) {
          setIsLoading(false);
        }
        return {
          wallet: shift,
          tickets: entries.filter((el) => el.includes(shift)).length,
        };
      })
    );
  }, [entries]);

  useEffect(() => {
    let isCancelled = false;

    if (entries && entriesIsLoading === false && !isCancelled) {
      getParticipants();
    }

    return () => {
      isCancelled = true;
    };
  }, [entries, entriesIsLoading, getParticipants]);

  let collator = new Intl.Collator(undefined, { numeric: true });

  return {
    participants: isLoading
      ? []
      : participants?.sort((a, b) => collator.compare(b.tickets, a.tickets)),
    isLoading,
  };
}

const creatorId = process.env.REACT_APP_CREATOR_ID;
const farmId = process.env.REACT_APP_FARM_ID;

export const useUnstakedNfts = (holderOnly: boolean) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const [nfts, setNfts] = useState<UnstakedData[]>([]);

  useEffect(() => {
    (async () => {
      if (!publicKey) return;
      if (holderOnly === false || !holderOnly) return;

      const walletNfts = await Metadata.findDataByOwner(connection, publicKey);

      const nfts = [];
      for (let nft of walletNfts)
        if (
          nft.data.creators &&
          nft.data.creators[0]?.verified &&
          nft?.data?.creators[0]?.address === creatorId
        )
          try {
            nfts.push({
              mint: new PublicKey(nft.mint),
              data: nft.data,
              json: { description: nft.data.name },
            });
          } catch (ex) {
            nfts.push({
              mint: new PublicKey(nft.mint),
              data: nft.data,
              json: { description: nft.data.name },
            });
          }

      let collator = new Intl.Collator(undefined, { numeric: true });
      nfts.sort((a, b) => collator.compare(a.data.name, b.data.name));
      setNfts(nfts);
    })();
  }, [publicKey, connection, holderOnly]);

  return nfts;
};

export const useStakedFarmers = (holderOnly: boolean) => {
  const { publicKey, wallet } = useWallet();
  const { connection } = useConnection();

  const [farmers, setFarmers] = useState(new Array<ProgramAccount>());

  useEffect(() => {
    (async () => {
      if (!wallet) return;
      if (!publicKey) return;
      if (holderOnly === false || !holderOnly) return;

      // Remove async from here
      const gf = initGemFarm(
        connection,
        wallet!.adapter as SignerWalletAdapter
      );

      if (farmId) {
        setFarmers(
          await gf.fetchAllFarmerPDAs(new PublicKey(farmId), publicKey)
        );
      }
    })();
  }, [connection, publicKey, wallet, holderOnly]);

  return farmers;
};

export const useStakedNFTs = (
  farmers: ProgramAccount<any>[],
  refreshHandle?: any
) => {
  const { publicKey, wallet } = useWallet();
  const { connection } = useConnection();

  const [nfts, setNfts] = useState<StakedData[]>([]);

  useEffect(() => {
    (async () => {
      if (!wallet) return;
      if (!publicKey) return;

      if (farmers) {
        const formatCurrentStakingNft = [];

        if (farmers !== null) {
          for (const farmer of farmers) {
            const currentStakingNft = await populateVaultNFTs(
              connection,
              wallet!.adapter as SignerWalletAdapter,
              farmer
            );

            if (currentStakingNft) {
              formatCurrentStakingNft.push(
                ...currentStakingNft?.map((e: any) => {
                  return {
                    mint: e.mint,
                    isStaked: true,
                    farmer: farmer,
                    json: e.externalMetadata,
                    data: e.onchainMetadata.data,
                  };
                })
              );
            }
          }
        }
        setNfts(formatCurrentStakingNft);
      }
    })();
  }, [connection, publicKey, wallet, refreshHandle, farmers]);

  return nfts;
};
