import { useTotalSnekBurnt } from "helpers/hooks";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const Footer = () => {
  const totalBurnt = useTotalSnekBurnt();

  return (
    <FooterWrapper>
      <Container>
        <FooterMain>Total $SNEK burnt through raffles.</FooterMain>
      </Container>
      <FooterLarge>{totalBurnt.toLocaleString()}</FooterLarge>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  overflow: hidden;
  position: relative;
  padding: 100px 0;

  @media only screen and (min-width: 768px) {
    padding: 150px 0;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 50px;
    padding: 200px 0;
  }
`;

const FooterMain = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const FooterLarge = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  font-family: "DK Black Mark Regular";
  font-size: 100px;
  font-weight: 800;
  justify-content: center;
  opacity: 0.1;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    font-size: 200px;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 250px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 300px;
  }
`;
