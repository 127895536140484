import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Link } from "react-router-dom";

import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";

import LogoSVG from "../images/logo.png";
import { useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import styled from "styled-components";

const Header = () => {
  const [ownedTokens, setOwnedTokens] = useState(null);
  const { connection } = useConnection();
  const { publicKey } = useWallet();

  useEffect(() => {
    (async () => {
      if (!publicKey) return;

      let tokenAccount = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        new PublicKey("ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx"),
        publicKey
      );

      let accountBalance = await connection.getTokenAccountBalance(
        tokenAccount
      );
      let adjustedBalance = Number(
        accountBalance["value"]["uiAmountString"]
      ).toFixed(2);
      setOwnedTokens(adjustedBalance);
    })();
  }, [connection, publicKey]);

  return (
    <TopContainer>
      <Link to="/" style={{ alignItems: "center", display: "flex" }}>
        <img
          src={LogoSVG}
          height={82}
          alt={"Zenjin Viperz"}
          className="small-logo"
        />
      </Link>
      <div style={{ alignItems: "center", display: "flex" }}>
        <SnekHeader>
          {publicKey && (
            <span style={{ marginRight: 20 }}>
              SNEK Balance: ${ownedTokens?.toLocaleString()}
            </span>
          )}
          <a
            href="https://famousfoxes.com/tokenmarket/ZNEc3wSpNycdsEtsccWXooa8fKb8n4rGC24Py6ZpyUx"
            target="_blank"
            style={{ marginRight: 20 }}
            rel="noreferrer"
          >
            Buy $SNEK
          </a>
        </SnekHeader>
        <WalletMultiButton
          className={publicKey ? "wallet-multi active" : "wallet-multi"}
        />
      </div>
    </TopContainer>
  );
};

export default Header;

const SnekHeader = styled.div`
  align-items: center;
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

const TopContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .long-logo {
    display: none;

    @media only screen and (min-width: 768px) {
      display: flex;
    }
  }

  .small-logo {
    @media only screen and (min-width: 768px) {
      margin-right: 16px;
    }
  }
`;
