import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { Container } from "react-bootstrap";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { useCallback, useMemo } from "react";
import toast, { Toaster } from "react-hot-toast";
import RaffleHome from "containers/RaffleHome";
import RaffleDetails from "containers/RaffleDetails";
import styled from "styled-components";

import Header from "./components/Header";
import Footer from "./components/Footer";

import { Switch, Route } from "react-router-dom";
import GetWinners from "containers/GetWinners";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = process.env.REACT_APP_RPC_ID;

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded
  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/solana-labs/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );

  const onError = useCallback((error) => {
    toast.error(
      error.message ? `${error.name}: ${error.message}` : error.name,
      {
        duration: 3000,
      }
    );
  }, []);

  return (
    <HelmetProvider>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} onError={onError} autoConnect>
          <WalletModalProvider>
            <Container>
              <PageWrapper>
                <Header />
                <Switch>
                  <Route exact path="/" component={RaffleHome} />
                  <Route path="/raffle/:id" exact component={RaffleDetails} />
                  <Route path="/entries/:id" exact component={GetWinners} />
                </Switch>
              </PageWrapper>
            </Container>
            <Footer />
          </WalletModalProvider>
          <Toaster position="top-center" reverseOrder={false} />
        </WalletProvider>
      </ConnectionProvider>
    </HelmetProvider>
  );
};

export default App;

const PageWrapper = styled.div`
  padding: 16px 0;
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    padding: 44px 0;
  }
`;
