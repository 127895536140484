import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { raffleDefaults } from "helpers/functions";
import { useEffect, useState } from "react";
import { SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID } from "./RaffleDetails";

const GetWinners = ({ match }: { match: any }) => {
  const raffle = raffleDefaults.find((element) => {
    return element.raffleObjectId === match.params.id;
  });
  const { connection } = useConnection();
  const { publicKey } = useWallet();

  const [entries, setEntries] = useState([]);

  useEffect(() => {
    getTotalTickets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTotalTickets = async () => {
    const test = await PublicKey.findProgramAddress(
      [
        new PublicKey(raffle.receiverWallet).toBuffer(),
        TOKEN_PROGRAM_ID.toBuffer(),
        new PublicKey(raffle.tokenMint).toBuffer(),
      ],
      SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    );

    if (test) {
      const sigs = await connection.getSignaturesForAddress(
        new PublicKey(test[0]?.toString()),
        {
          limit: 1000,
        }
      );

      sigs?.forEach(async (sig) => {
        if (sig?.err === null) {
          const trans = await connection.getParsedTransaction(sig.signature);

          const ownerObject = trans?.meta?.logMessages?.filter((word) =>
            word.includes("Program log:")
          );

          const owner = ownerObject[0].substring(23);

          const array = Array.from(
            {
              length: Number(
                sig.memo && raffle.raffleId.length > 1
                  ? sig.memo.substring(13, 15) === raffle.raffleId
                    ? raffle.type === "whitelist"
                      ? sig.memo
                          .substring(
                            sig.memo.indexOf(""),
                            sig.memo.lastIndexOf(";")
                          )
                          .substring(18)
                      : sig.memo.substring(18)
                    : 0
                  : sig.memo.substring(13, 14) === raffle.raffleId
                  ? raffle.type === "whitelist"
                    ? sig.memo
                        .substring(
                          sig.memo.indexOf(""),
                          sig.memo.lastIndexOf(";")
                        )
                        .substring(17)
                    : sig.memo.substring(17)
                  : 0
              ),
            },
            (v, i) => `${owner} - ${sig.memo}`
          );

          array.forEach((a) => {
            setEntries((entries) => [...entries, a]);
          });
        }
      });
    }
  };

  if (
    publicKey?.toString() === "GRDCbZBP1x2JxYf3rQQoPFGzF57LDPy7XtB1gEMaCqGV" ||
    publicKey?.toString() === "FWDuBfxJ1CT3PVpr5m43j1dsLmvyN6ppmVJK7LCq3XEg"
  ) {
    return (
      <div>
        <h3>Entries</h3>
        <ul>
          {entries.map((entry, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i}>{entry}</li>
          ))}
        </ul>
      </div>
    );
  } else {
    return <div>Unauthorised</div>;
  }
};

export default GetWinners;
